
 .home-page {
  display: flex;
  justify-items:center;
  flex-direction: column; /* Default for smaller screens */
}

@media (min-width: 768px) {
  .home-page {
    flex-direction: row; 
  }
}


.content-container {
  flex-grow: 1;
  overflow-y: auto;
  margin-left: 0; 
  
}

@media (min-width: 768px) {
  .content-container {
    margin-left: 250px; 
  }
}

.messages-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.user-list {
  width: 30%;
  border-right: 1px solid #ccc;
  overflow-y: auto;
}

.chatbox-container {
  flex-grow: 1;
  overflow-y: auto;
}
.full-feedback {
  padding-top: 0;
  padding-bottom: 0;
 
}
