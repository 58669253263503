

.layout-container {
    display: flex;
    height: 100vh; 
   
  }
  
  .sidebar {
    width: 250px;
    background-color: #fff;
    border-right: 1px solid #dbdbdb;
    padding: 20px;
    height: 100vh; 
    position: fixed;
    top: 0;
    left: 0;
  
  }
  
  .content-container {
     
    padding: 20px;
    flex-grow: 1;
  
  }
  