.post-container {
  display: flex;
  flex-wrap: wrap; 
  justify-content:flex-start; 
  width: 100%;
  height: fit-content;
  
}

.post {
  width: 100%; 
  max-width: 100%; 
  margin: 10px; 
  border: 1px solid #ccc; 
  border-radius: 10px; 
  overflow: hidden;
  align-items: start;
 

}

.post img {
  align-items: flex-start;
  max-width: 900px;
  width: 100%; 
  height: auto; 
  object-fit: cover; 
}
.shimmer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
}

.shimmer-image {
  border-radius: 10px;
  margin-bottom: 10px;
}

.shimmer-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.shimmer-text {
  margin-bottom: 10px;
}

.shimmer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.post-icon {
  cursor: pointer;
  margin-right: 10px;
  font-size: 20px;
}

.post-icon.liked {
  color: rgb(237, 33, 67);
}
.highlighted-post {
  background-color: yellow;
  transition: background-color 0.5s ease-out;
}

#hr
{
  border-color: black;
}