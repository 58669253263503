.user-list {
  max-width: 100%; /* Ensures full width of the screen */
  padding: 0;
}

.user-item {
  width: 100%; /* Full width of the container */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  position: relative;
  transition: background-color 0.3s;
}

.user-item.highlighted {
  background-color: #e0f7fa;
}

.user-item:hover {
  background-color: #f1f1f1;
}

.badge {
  font-size: 0.8rem;
  padding: 5px 8px;
}
