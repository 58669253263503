.public-layout-container {
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 100vh; 
  }
  
  .content-container {
    width: 100%;
    max-width: 600px; 
    padding: 20px;
    box-sizing: border-box;
  }
  