/* General styling for the form */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url('https://mystaticimages.s3.ap-south-1.amazonaws.com/logo+clear.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
 
}

.login-form {
  background-color: (rgb(255, 255, 255), 0.973);
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 700px;
  margin: 1rem;
}

.form-group {
  margin-bottom: 1rem;
  position: relative;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}
.custom-loader {
  width:50px;
  height:50px;
  border-radius:50%;
  background:#766DF4;
  -webkit-mask:
    repeating-conic-gradient(#0000 0deg,#000 1deg 70deg,#0000 71deg 90deg),
    radial-gradient(farthest-side,#0000 calc(100% - 9px),#000 calc(100% - 8px));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation:s5 1s infinite ;
}

@keyframes s5 {to{transform: rotate(.5turn)}}
input.form-control {
  width: 100%;
  font-size: 1rem;
  border: 2px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  padding-right: 40px; /* Space for the eye icon */
}

/* Button within form-group for eye icon */
.form-group button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
}

/* General button styling */
button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #0056b3;
}
