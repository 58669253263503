.chat-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 600px;
  overflow-y: auto;
}

.chat-header {
  display: flex;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.back-button {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: white;
  margin-right: 10px;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #f9f9f9;
}

.message {
  display: inline-block;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 16px;
  max-width: 60%;
  position: relative;
  word-wrap: break-word;
}

.message img {
  border-radius: 8px;
  max-width: 100%;
  margin-top: 5px;
}

.logged-in-user-message {
  background-color: #dcf8c6;
  color: black;
  align-self: flex-end; 
  margin-left: auto;
  margin-bottom: 12px;
}

.logged-in-user-message::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent #dcf8c6;
}

.selected-user-message {
  background-color: #ffffff; 
  color: black;
  align-self: flex-start; 
  margin-right: auto;
  margin-bottom: 12px;
}

.selected-user-message::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #ffffff transparent transparent;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  position: sticky;
  bottom: 0;
  z-index: 1000;
}

.chat-input input[type="text"] {
  flex-grow: 1;
  margin-right: 10px;
}

.chat-input .btn {
  margin-left: 5px;
}


.media-preview img,
.media-preview video,
.media-preview audio {
  max-width: 100%;
  max-height: 150px;
  object-fit: cover; 
}


.media-container {
  max-width: 200px;
  max-height: 200px;
  overflow: hidden;
}

.media {
  width: 100%;
  height: auto;
}

.media-preview .media {
  max-width: 200px;
  max-height: 200px;
}
.reply-preview .media-container {
  max-width: 100px;
  max-height: 100px;
}

.reply-preview .media {
  width: 100%;
  height: auto;
}
.media {
  max-width: 100%;
  height: auto;
  display: block;
}

.media-small {
  max-width: 100px; /* Adjust this value as needed */
  height: auto;
  display: block;
}
.status-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 20px;
  padding: 0;
}

.status-indicator.online {
  background-color: green;
}

.status-indicator.offline {
  background-color: red;
}

.audio-player {
  width: 100%;
}

.media-container audio {
  width: 100%;
  height: 30px;
}